// Fonts

@font-face {
    font-family: 'Berlin Type';
    src: url(../assets/fonts/BerlinTypeWeb-Regular.woff2);
    font-weight: 400;
}

@font-face {
    font-family: 'Berlin Type';
    src: url(../assets/fonts/BerlinTypeWeb-Bold.woff2);
    font-weight: 700;
}

// Bootstrap Variables Customized
@import './bootstrap-customized.scss';

// Bootstrap All Components
@import '../../node_modules/bootstrap/scss/bootstrap';

@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css';

// Further Custom Styles
b, strong {
    font-weight: 800;
}

h1,
h2,
h3,
.btn,
.card-title {
    font-weight: bold;
    text-transform: uppercase;
}




picture {
    display: block;
    img {
        max-width: 100%;
        height: auto;
    }
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.btn-outline-primary {
  &:hover {
    color: #fff;
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #fff;
  }
}

.btn-primary {
  color: #fff;
  &:hover, &:focus {
    color: #fff;
    
    background-color: #666;
    border-color: #666;
    box-shadow: 0 6px 12px 0 rgb(0 0 0 / 20%);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: #fff;
  }
}

.btn.rounded-pill {
  font-size: 14px;
  padding: 0.25rem 0.5rem;

  @media (min-width: 581px) {
    font-size: 16px;
    padding: 0.325rem 0.75rem;
  }
}

label {
  font-weight: bold;
}

.btn {
  text-align: left;
}