@import '../../../styles/bootstrap-customized.scss';

.footer {
    background-color: #f2f5f8;
    padding: 2rem 0;

    .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    p {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
    }

    figure {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 120px;
        flex: 0 0 120px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .toplink {
        position: fixed;
        right: 2rem;
        bottom: 2rem;
        width: 48px;
        height: 48px;
        border: 1px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 1);
        visibility: hidden;
        opacity: 0;
        transform: translateY(96px);
        transition: all 0.3s 0s ease;
    }

    .toplinkVisible {
        visibility: visible;
        opacity: 1;
        transform: none;
    }
}
