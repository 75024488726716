.navigation {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
    z-index: 20;

    :global {
        .navbar {
            .nav-link {
                color: black;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.25rem;
            }
            .dropdown-item {
                .nav-link {
                    font-weight: 400;
                    color: #000;
                    text-decoration: none;
                    font-size: 1.125rem;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
