@mixin stickyBase {
    background-color: #fff;
    position: sticky;
    top: -1px;
    z-index: 10;
}

.filterWrapper {
    @include stickyBase;
    padding-bottom: 3rem;
}

.filterWrapperStuck {
    @include stickyBase;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.stuck {
    @include stickyBase;
    padding-top: 44px !important;
    padding-bottom: 1rem !important;

    h2 {
        visibility: hidden;
    }
}
